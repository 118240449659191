<template>
  <div class="list-info">
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入场所名或出租房名搜索" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <van-popup v-model="objectShow" position="bottom"  >
      <van-picker title="巡查对象" show-toolbar :columns="objectList" value-key="label" @confirm="objectConfirm" @cancel="objectShow = false"  />
    </van-popup>
    <van-popup v-model="visitTypeShow" position="bottom">
      <van-picker title="巡查类别" show-toolbar :columns="visitTypeList" value-key="label"
                  @confirm="visitTypeConfirm" @cancel="visitTypeShow = false"/>
    </van-popup>
    <van-popup v-model="statusShow" position="bottom"  >
      <van-picker title="任务状态" show-toolbar :columns="statusList" value-key="label" @confirm="statusConfirm" @cancel="statusShow = false"  />
    </van-popup>
    <div class="filterBar">
      <van-row>
        <van-col span="6">
          <p @click="visitTypeShow = !visitTypeShow" :style="{color:visitTypeShow?'#3E7FF6': '' }">巡查类别<van-icon name="arrow-down" /></p>
<!--          <p @click="belongShow = !belongShow" :style="{color:belongShow?'#3E7FF6': '' }">{{belongTitle}}<van-icon name="arrow-down" /></p>-->
        </van-col>
        <van-col span="6">
          <p @click="objectShow = !objectShow" :style="{color:objectShow?'#3E7FF6': '' }">{{objectTitle}}<van-icon name="arrow-down" /></p>
        </van-col>
        <van-col span="6">
          <p @click="statusShow = !statusShow" :style="{color:statusShow?'#3E7FF6': '' }">{{statusTitle}}<van-icon name="arrow-down" /></p>
        </van-col>
        <van-col span="6" @click="moreCancel">重置</van-col>
      </van-row>
    </div>
     <div class="cont">
       <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
         <van-cell>
           <template #title>
             <p class="list_totalCount">共有{{totalCount}}条数据</p>
           </template>
         </van-cell>
         <van-cell @click="goInfo(item)" v-for="(item, index) in dataList" :key="index">
           <van-cell-group >
             <van-cell class="title" center>
               <van-row>
                 <van-col :span="18">{{item.name}}</van-col>
                 <van-col :span="6" :style="{color:item.taskStatus == 0?'#1BD972': (item.taskStatus == -1? '#347DF5' : '#29BFB8')}">{{item.taskStatus == 0?'进行中':(item.taskStatus == -1? '待申领' : '已完成')}}</van-col>
               </van-row>
               <van-row>
                 <van-col :span="22">{{item.address}}</van-col>
                 <van-col :span="2"><van-icon name="arrow" /></van-col>
               </van-row>
               <van-row>
                 <van-col :span="18">{{item.targetStr}}</van-col>
               </van-row>
               <van-row>
                 <van-col :span="8">任务次数：<span :style="item.finished==item.times?'color: #2E73F8':'color: #FE9A30'">{{item.finished}}</span>/<span style="color: #2E73F8">{{item.times}}</span></van-col>
                 <van-col :span="16">
                   <van-button type="info" round size="small" v-show="item.taskStatus == -1" @click.stop="applyTask(item.dtrId, item.cycleNum, item.targetId)" style="float:right">申领任务</van-button>
                   <van-button type="info" round size="small" v-show="item.taskStatus == 0" style="float:right" @click.stop="goSubmit(item)">上传结果</van-button>
                 </van-col>
               </van-row>
             </van-cell>
             <van-cell class="bottom" center>
               <van-row>
                 <van-col :span="7" style="color:#666">{{item.scopeType === 1? '社区' : '网格'}}任务</van-col>
                 <van-col :span="12" style="color:#EA4749">{{item.endTimeStr}}前完成</van-col>
                 <van-col :span="5" style="color:#666">{{item.cooling}}</van-col>
               </van-row>
             </van-cell>
           </van-cell-group>
         </van-cell>
       </van-list>
       <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
     </div>
  </div>
</template>
<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>
<script>
import topBar from '@/components/topBar/topBar'
import {formatterDate} from '@/utils/utils'
import { mapMutations } from 'vuex'
export default {
  components :{
    topBar
  },
  data() {
    return {
      activeName: '',
      loading: false,
      finished: false,
      totalCount: 0,
      limit: 10,
      page: 0,
      dataForm: {
        taskStatus: '',
        scopeType: '',
        visitType: '',
        objectType: ''
      },
      objectTitle: '巡查对象',
      statusTitle: '任务状态',
      dataList: [],
      searchValue: '',
      objectList: [{ value: 1, label: "场所" }, { value: 2, label: "出租房" }],
      statusList: [
        { label: '待申领', value: -1 },
        { label: '进行中', value: 0 },
        { label: '已完成', value: 1 }],
      visitTypeList: [],
      statusShow: false,
      objectShow: false,
      belongShow: false,
      visitTypeShow: false,
    }
  },
  methods: {
    ...mapMutations,
    goInfo(item) {
      this.$store.commit('setPatrolTask', item)
      this.$router.push('/patrolInfo')
    },
    // 获取字典
    getDictTypeList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'inspectionType',
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.visitTypeList = data.dicts
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getDataList() {
      this.page++
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/dailytasks/org/inspection/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          name: this.searchValue,
          type: 2,
          orgId: this.$orgId,
          taskStatus: this.dataForm.taskStatus,
          dictId: this.dataForm.visitType,
          objectType: this.dataForm.objectType
        })
      }).then(({data}) => {
        if (data.code == 0) {
          data.page.list.map(item => {
            if (item.objectType === 1) {
              if (item.categoryTypeStr) {
                item['object'] = item.categoryTypeStr
              }
              if (item.operateTypeStr) {
                item['object'] = item.operateTypeStr
              }
              if (item.categoryTypeStr && item.operateTypeStr) {
                item['object'] = item.categoryTypeStr + ',' + item.operateTypeStr
              }
            } else if (item.objectType === 2) {
              item['object'] = '出租房'
            }
          })
          this.dataList = this.dataList.concat(data.page.list)
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        // this.$toast.fail(data.msg);
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    onSearch() {
      this.page = 0
      if (this.searchValue.indexOf('-') > -1) {
        this.dataForm.objectType = 1
      } else {
        this.dataForm.objectType = 2
      }
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.dataForm.objectType = ''
      this.finished = false
      this.getDataList()
    },
    moreCancel () {
      this.objectTitle = '巡查对象'
      this.statusTitle = '任务状态'
      this.dataForm.taskStatus = ''
      this.dataForm.scopeType = 1
      this.dataForm.visitType = ''
      this.dataForm.objectType = ''
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    objectConfirm (value) {
      this.page = 0
      this.dataList = []
      this.dataForm.objectType = value.value
      this.getDataList()
      this.objectShow = false
    },
    visitTypeConfirm(value) {
      this.page = 0
      this.dataList = []
      this.dataForm.visitType = value.value
      this.belongTitle = value.label
      this.getDataList()
      this.visitTypeShow = false
    },
    statusConfirm (val) {
      this.dataForm.taskStatus = val.value
      this.statusTitle = val.label
      this.page = 0
      this.dataList = []
      this.getDataList()
      this.statusShow = false
    },
    applyTask (dtrId, cycleNum, targetId) {
      this.$dialog.confirm({
        message: '确定申领任务？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/dailytasks/claim`),
          method: 'post',
          params: this.$http.adornParams({
            dtrId,
            cycleNum,
            targetId,
            taskStatus: 0
          })
        }).then(({data}) => {
          if (data.code == 0) {
            this.$toast.success({
              message: '申领成功',
              duration: 1500,
              onClose: () => {
                this.page = 0
                this.dataList = []
                this.getDataList()
              }
            })
          }
        })
      })
    },
    goSubmit (item) {
      debugger
      if (item.dttId) {
        this.$router.push({path: '/censor-add', query: {tasks: true, dictId: item.dictId, dttId: item.dttId, targetId: item.targetId, address: item.address, objectType: item.objectType}})
      } else {
        this.$router.push({path: '/patrol-add', query: {tasks: true, dictId: item.dictId, dttId: item.dttId, targetId: item.targetId, address: item.address, objectType: item.objectType}})
      }
    }
  },
  created () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    })
    this.getDataList()
    this.getDictTypeList()
  }
}
</script>

<style lang="scss" scoped>
.notice {
  padding-top: 100px;
  .tab {
    //border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  .cont {
    .van-cell {
      position: relative;
      padding: 15px 20px 0;
      margin-bottom: 30px;
      border-radius: 10px;

      .title {
        margin-bottom: 20px !important;

        .van-icon {
          font-size: 18px;
          margin-top: 25px;
        }

        .van-row:nth-child(1) .van-col:nth-child(1) {
          font-size: 34px;
          font-weight: 600;
        }

        .van-row:nth-child(1) .van-col:nth-child(2) {
          padding-left: 30px;
        }

        .van-row:nth-child(2) .van-col:nth-child(1) {
          font-size: 32px;
          color: #5d5d5d;
        }

        .van-row:nth-child(3) .van-col:nth-child(1) {
          font-size: 32px;
          font-weight: 500;
          color: #000;
        }

        .van-row:nth-child(4) {
          color: #5d5d5d;
        }

        .van-row:nth-child(4) .van-col:nth-child(2) {
          .van-button {
            padding: 0 37px;
            margin-left: 14px;
          }
        }
      }

      .bottom {
        border-top: 1px solid #F1F1F1;
        font-size: 28px;
        color: #333;
        text-align: center;
        margin: 20px 10px;
      }
    }
  }
}
::v-deep {
  .van-cell::after {
    border-bottom: none;
  }
  .van-cell-group::after {
    border-bottom: none;
  }
}
.list_totalCount {
  padding: 10px 20px;
}
</style>
